import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Team } from '../../models/team/team';
import { ResponsePayloadDTO } from 'src/app/models/salesAPIResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TeamService implements OnInit {

  constructor(private httpClient: HttpClient) {}

  ngOnInit() {

  }

  getTeams(): Observable<ResponsePayloadDTO<Team[]>> {
    return this.httpClient.get<ResponsePayloadDTO<Team[]>>(`${environment.configurationsApiBaseUrl}/teams`);
  }


  getTeamById(id : number): Observable<ResponsePayloadDTO<Team>> {
    return this.httpClient.get<ResponsePayloadDTO<Team>>(`${environment.configurationsApiBaseUrl}/teams/`+id);
  }

  deleteTeam(id: number): Observable<ResponsePayloadDTO<boolean>> {
    return this.httpClient.delete<ResponsePayloadDTO<boolean>>(`${environment.configurationsApiBaseUrl}/teams/`+id);
  }

  updateTeam(team: Team): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.put<ResponsePayloadDTO<number>>(`${environment.configurationsApiBaseUrl}/teams`, team);

  }

  saveTeam(team: Team): Observable<ResponsePayloadDTO<number>> {
    return this.httpClient.post<ResponsePayloadDTO<number>>(`${environment.configurationsApiBaseUrl}/teams`, team);

  }

}
